import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'

import Content from './Content'
import Title from './Title'
import Image from './Image'

const Heading = styled(Title)`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.primary};
`

const Images = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const ImageWrapper = styled.div`
  margin-bottom: 48px;
  position: relative;
  z-index: 1001;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 24px;
  }
  img {
    display: block;
    max-width: 200px;
    height: 100px;
    object-fit: contain;
    margin-right: 20px;
  }
`

const BlogContent = ({ contentTitle, contentImages }) => {
  return (
    <Content>
      <Row middle="xs">
        <Col xs={8} xsOffset={1} md={7}>
          <Heading tag="h4" content={contentTitle} />
          <Images>
            {contentImages.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ImageWrapper key={index}>
                <Image image={item.image} {...item.imageSharp} />
              </ImageWrapper>
            ))}
          </Images>
        </Col>
      </Row>
    </Content>
  )
}

export default BlogContent
