/* eslint-disable no-underscore-dangle */
import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'

import Link from './Link'
import Title from './Title'
import Image from './Image'

import { asText, renderDate } from '../utils/prismic'

const Post = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  margin-bottom: 48px;
  z-index: 1001;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 64px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-bottom: 72px;
  }
`

const ImageWrapper = styled.div`
  background: ${({ theme }) => theme.colors.gray10};
  margin-bottom: 48px;
  position: relative;
  width: 100%;
  z-index: 1001;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 24px;
  }
  img {
    /* position: absolute; */
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  padding: 28px 24px 25px;
`

const PostLink = styled(Link)`
  background: none;
  color: inherit;
  &:visited {
    color: inherit;
  }
`

const Heading = styled(Title)`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.primary};
`
const Date = styled.div`
  font-size: 14px;
  margin-bottom: 16px;
`

const BlogList = ({ articles }) => {
  return (
    <Row>
      {articles.map(({ node }, index) => (
        <Col
          xs={8}
          xsOffset={1}
          sm={4}
          smOffset={index % 2 === 0 ? 1 : 0}
          key={node._meta.id}
        >
          <Post>
            <ImageWrapper>
              <Image image={node.image} {...node.imageSharp} />
            </ImageWrapper>
            <ContentWrapper>
              <div>
                <Date>{renderDate(node.date)}</Date>
              </div>
              <PostLink link={{ meta: node._meta }}>
                <Heading tag="h4" content={node.title} />
                <p>{asText(node.content).slice(0, 180)}...</p>
              </PostLink>
            </ContentWrapper>
          </Post>
        </Col>
      ))}
    </Row>
  )
}

export default BlogList
