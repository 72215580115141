import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Content from '../components/Content'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Highlight, { isHighlightDataValid } from '../components/Highlight'
import Separator from '../components/Separator'
import BlogList from '../components/BlogList'
import BlogContent from '../components/BlogContent'

export const query = graphql`
  query BlogQuery {
    prismic {
      data: blog_main(uid: "blog", lang: "en-gb") {
        metaTitle: meta_title
        metaDescription: meta_description
        metaImage: meta_image
        heroLabel: hero_label
        heroTitle: hero_title
        heroBody: hero_body
        heroImage: hero_image
        heroImageSharp: hero_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        intro
        contentTitle: content_title
        contentImages: content_images {
          image
          imageSharp {
            childImageSharp {
              fixed(width: 300) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      allArticles(sortBy: date_DESC) {
        articles: edges {
          node {
            _linkType
            _meta {
              uid
              id
              type
            }
            title
            content
            date
            image
            imageSharp {
              childImageSharp {
                fluid(maxWidth: 604) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

const BlogPage = ({
  data: {
    prismic: { data, allArticles },
  },
}) => {
  return (
    <Layout {...data}>
      <Hero {...data} />
      <Separator />
      <Content>
        <Highlight {...data} />
        {isHighlightDataValid(data) && <Separator />}
        <BlogList {...allArticles} />
        <BlogContent {...data} />
      </Content>
    </Layout>
  )
}

export default BlogPage
